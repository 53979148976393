import { Grid } from "@mui/material";
import React from "react";
import {
  whyChooseUsItems,
  whyChooseUsLabel,
} from "../../../constants/whyChooseUs";
import {
  WhyChooseResponseContainer,
  WhyChooseWrap,
} from "../../../styles/whyChooseUs";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";
import WhyChooseUsWrapper from "../../shared/WhyChooseUsWrapper";

export default function WhyChooseUs() {
  return (
    <>
      <WhyChooseWrap container>
        <Grid
          item
          xs={12}
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <CustomHeadingWrapper label={whyChooseUsLabel} />
        </Grid>
        {whyChooseUsItems?.map((item, index) => {
          return (
            <WhyChooseResponseContainer
              item
              key={index}
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              data-aos="flip-up"
              data-aos-duration="1500"
            >
              <WhyChooseUsWrapper whyChooseDatas={item} />
            </WhyChooseResponseContainer>
          );
        })}
      </WhyChooseWrap>
    </>
  );
}
