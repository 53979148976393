import customerSupport from "../assets/whyChooseUsImages/customerSupport.svg";
import commitment from "../assets/whyChooseUsImages/commitment.svg";
import incrementalInnovation from "../assets/whyChooseUsImages/incrementalInnovation.svg";
import productOwnership from "../assets/whyChooseUsImages/productOwnership.svg";
import codeQuality from "../assets/whyChooseUsImages/codeQuality.svg";
import agileMethodology from "../assets/whyChooseUsImages/agileMethodology.svg";

export const whyChooseUsLabel = "Why Choose Us";

export const whyChooseUsItems = [
  {
    id: 1,
    icon: codeQuality,
    HeadingLabel: "Code Quality",
    content: "Code Quality is our motto",
  },
  {
    id: 2,
    icon: incrementalInnovation,
    HeadingLabel: "Incremental Innovation Delivered",
    content: "We transform your product ideas into actual products",
  },
  {
    id: 3,
    icon: productOwnership,
    HeadingLabel: "Product Ownership",
    content: "We care for your products as if its our own",
  },
  {
    id: 4,
    icon: commitment,
    HeadingLabel: "Commitment",
    content: "  Committed to Making your Business Shine",
  },
  {
    id: 5,
    icon: agileMethodology,
    HeadingLabel: "Agile Methodology",
    content: "  Agile Method is our ThumbRule",
  },
  {
    id: 6,
    icon: customerSupport,
    HeadingLabel: "Customer Support",
    content: "Customer is the king. You will be treated as one",
  },
];
