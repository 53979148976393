import {
  angularIcon,
  cloneCodeIcon,
  commitmentQuality,
  empathyToCustomer,
  nodeIcon,
  OwnershipIcon,
  reactIcon,
  reactNativeIcon,
  vueIcon,
} from "./iconPath";

export const specificationItems = [
  { id: 1, icon: cloneCodeIcon, label: "Lovers of Clean Code" },
  { id: 2, icon: OwnershipIcon, label: "Ownership with Care" },
  { id: 4, icon: commitmentQuality, label: "Commitment to Quality" },
  { id: 4, icon: empathyToCustomer, label: "Empathy to Customer" },
];

export const TechnologyExpertiseLabel = "Technology Expertise";
export const technologyExpertiseItems = [
  { id: 1, icon: reactIcon, label: "React Js" },
  { id: 2, icon: angularIcon, label: "Angular Js" },
  { id: 3, icon: vueIcon, label: "Vue Js" },
  { id: 4, icon: reactNativeIcon, label: "React Native" },
  { id: 5, icon: nodeIcon, label: "Node Js" },
];
