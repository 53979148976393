import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const OurClientCarouselContainer = styled(Grid)({
  width: "60%",
  boxSizing: "border-box",
  margin: "auto",
});
export const OurClientImageContainer = styled(Grid)({
  padding: "20px 30px",
  margin: "auto",
  height: "110px",
  display: "flex",
  alignItems: "flex",
  justifyContent: "center",
  width: "20%",
});
export const OurClientImage = styled("img")(({ style }) => {
  return {
    width: "180px",
    height: "50px",
    ...style,
  };
});
