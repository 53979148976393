// import aquabazzar from "../assets/clients/aquabazzar.png";
import aquaconnect from "../assets/clients/aquaconnect.svg";
import ClassActLogo from "../assets/clients/ClassActLogo.png";
import client_1 from "../assets/clients/client-1.png";
import client_2 from "../assets/clients/client-2.png";
import client_3 from "../assets/clients/client-3.png";
import client_4 from "../assets/clients/client-4.png";
import client_5 from "../assets/clients/client-5.png";
import client_6 from "../assets/clients/client-6.png";
import derby from "../assets/clients/derby-svg-logo.svg";
import Diamondpick_Logo from "../assets/clients/Diamondpick_Logo.png";
import digitalxc_logo from "../assets/clients/digitalxc-logo.png";
import ESGMSlogo from "../assets/clients/ESGMSlogo.jpg";
import fairmart from "../assets/clients/fairmart_new.png";
import foodApp from "../assets/clients/foodApp.png";
import hippoStores from "../assets/clients/hippoStores.jpg";
import hippo_videos from "../assets/clients/hippo-videos.png";
import magzlogo from "../assets/clients/magzlogo__.svg";
import mobiQube from "../assets/clients/mobiQube_new.png";
import partswizz from "../assets/clients/partswizz_new.png";
import phygital from "../assets/clients/phygital_new.jpg";
import redflex from "../assets/clients/redflex.png";
import smartLeads from "../assets/clients/smartLeads.svg";
import tigerAnalastics from "../assets/clients/tigerAnalastics.png";
import TrimbleR_Horiz from "../assets/clients/TrimbleR-Horiz-RGB-Blue.svg";

export const ourClientsLabel = "Our Clients";

export const ourClientsItems = [
  // { id: 1, icon: aquabazzar },
  { id: 1, icon: aquaconnect },
  { id: 2, icon: ClassActLogo },
  { id: 3, icon: client_1 },
  { id: 4, icon: client_2 },
  { id: 5, icon: client_3 },
  { id: 6, icon: client_4 },
  { id: 7, icon: client_5 },
  { id: 8, icon: client_6 },
  { id: 9, icon: derby },
  { id: 10, icon: Diamondpick_Logo },
  { id: 11, icon: digitalxc_logo },
  { id: 12, icon: ESGMSlogo },
  { id: 13, icon: foodApp },
  { id: 14, icon: hippoStores },
  { id: 15, icon: hippo_videos },
  { id: 16, icon: magzlogo },
  { id: 17, icon: fairmart, style: { height: "fit-content", width: "100%" } },
  { id: 18, icon: phygital, style: { height: "80px", width: "100%" } },
  { id: 19, icon: mobiQube, style: { height: "80px", width: "100%" } },
  { id: 20, icon: partswizz, style: { height: "80px", width: "100%" } },
  { id: 21, icon: redflex },
  { id: 22, icon: smartLeads },
  { id: 23, icon: tigerAnalastics },
  { id: 24, icon: TrimbleR_Horiz },
];
