import { Grid } from "@mui/material";
import React from "react";
import { CopyRightText } from "../../../styles/copyRights";

export default function CopyRights() {
  return (
    <>
      <Grid container>
        <CopyRightText itme xs={12}>
          Copyright ©2022 - Vhitechlab is a brand of Virdhi Technology Pvt Ltd.
          All Rights Reserved
        </CopyRightText>
      </Grid>
    </>
  );
}
