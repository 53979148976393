/* eslint-disable jsx-a11y/iframe-has-title */
import { Grid } from "@mui/material";
import React from "react";
import {
  ContackAddressText,
  ContactAddressContainer,
  contactCenterStyle,
  ContactCityName,
  contactHeadingStyles,
  ContactMapContainer,
  mapIframeStyles,
} from "../../../styles/contactUs";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";

const contactUsLabel = "Contact Us";

export default function ContactUs() {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeadingWrapper
            label={contactUsLabel}
            style={contactHeadingStyles}
          />
        </Grid>
        <Grid item xs={10.5} sm={11} md={12} m="auto">
          <Grid container sx={{ ...contactCenterStyle }}>
            <ContactMapContainer
              item
              xs={12}
              sm={12}
              md={6}
              lg={5.5}
              // data-aos="zoom-in"
              // data-aos-duration="2000"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.608951246888!2d80.20724871482228!3d12.996844990839307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52679119993093%3A0x7971a4d3a96df189!2sVirdhi%20Tech%20Lab!5e0!3m2!1sen!2sin!4v1651493644720!5m2!1sen!2sin"
                style={mapIframeStyles}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </ContactMapContainer>
            <ContactAddressContainer
              item
              xs={12}
              sm={12}
              md={6}
              lg={5.5}
              // data-aos-duration="1000"
              // data-aos="zoom-in"
            >
              <ContactCityName>CHENNAI</ContactCityName>
              <ContackAddressText>
                15, Gopalakrishnan Street, Secretariat Colony,
              </ContackAddressText>
              <ContackAddressText>Adambakkam,</ContackAddressText>
              <ContackAddressText> Chennai - 600032</ContackAddressText>
            </ContactAddressContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
