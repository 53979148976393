import React from "react";
import {
  WhyChooseContentText,
  WhyChooseHeadingText,
  WhyChooseIcon,
  WhyChooseIconContainer,
  WhyChooseUsContainer,
} from "../../styles/whyChooseUs";

export default function WhyChooseUsWrapper({ whyChooseDatas }) {
  return (
    <>
      <WhyChooseUsContainer>
        <WhyChooseIconContainer>
          <WhyChooseIcon
            src={whyChooseDatas?.icon}
            alt={whyChooseDatas?.HeadingLabel}
          />
        </WhyChooseIconContainer>
        <WhyChooseHeadingText>
          {whyChooseDatas?.HeadingLabel}
        </WhyChooseHeadingText>
        <WhyChooseContentText>{whyChooseDatas?.content}</WhyChooseContentText>
      </WhyChooseUsContainer>
    </>
  );
}
