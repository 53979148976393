import { Grid } from "@mui/material";
import React from "react";
import { specificationItems } from "../../../constants/home";
import {
  SpecificationContainer,
  SpecificationIconContainer,
  SpecificationLogo,
  SpecificationText,
} from "../../../styles/home";

export default function CompanySpecification() {
  return (
    <>
      <SpecificationContainer container>
        {specificationItems?.map((item, index) => {
          return (
            <Grid item xs={12} key={index} sm={3} md={3}>
              <SpecificationIconContainer>
                <SpecificationLogo>{item?.icon}</SpecificationLogo>
                <SpecificationText>{item?.label}</SpecificationText>
              </SpecificationIconContainer>
            </Grid>
          );
        })}
      </SpecificationContainer>
    </>
  );
}
