import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import backgroundImage from "../assets/backgroundImage.png";

export const CopyRightsBgContainer = styled(Box)({
  padding: "10px",
  boxSizing: "border-box",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#111",
});
export const CopyRightText = styled(Grid)({
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  fontWeight: "normal",
  color: "#fff",
  textAlign: "center",
  fontSize: "0.75rem",
});
