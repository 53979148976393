import avinash from "../assets/TeamImages/avinash_3.jpg";
import gopi from "../assets/TeamImages/gopi2.jpg";
import jaison from "../assets/TeamImages/jaison.jpg";
import madhu from "../assets/TeamImages/madhu.jpg";
import priya from "../assets/TeamImages/priya.jpg";
import raja from "../assets/TeamImages/raja.jpg";
import rajesh from "../assets/TeamImages/rajesh3.jpg";
import sindhudevan from "../assets/TeamImages/sindhudevan.jpeg";
import sundhar from "../assets/TeamImages/sundar.jpg";
import sat from "../assets/TeamImages/sat.jpg";
import partha from "../assets/TeamImages/partha.jpg";
import amirtha from "../assets/TeamImages/amirtha1.jpg";
import vinoth from "../assets/TeamImages/vinoth_E.jpg";
import vignesh from "../assets/TeamImages/vicky10.jpg";
import siva from "../assets/TeamImages/siva.jpeg";
import arudhra from "../assets/TeamImages/arudhra_2.jpeg";
import vinith from "../assets/TeamImages/vinith_2.jpg";
import surej from "../assets/TeamImages/surej7.jpg";
import arun from "../assets/TeamImages/arun2.jpg";
import mukesh from "../assets/TeamImages/mukesh2.jpg";

import kanagalakshmi from "../assets/TeamImages/Kanagalakshmi1.jpg";

import vinotha from "../assets/TeamImages/vinotha5.jpg";
import charumathy from "../assets/TeamImages/charumathy1.jpg";
import vignesh_v from "../assets/TeamImages/vigneshV.jpg";

import revathi from "../assets/TeamImages/revathi.jpg";
import dinesh from "../assets/TeamImages/Dinesh.jpg";
import vinoth_s from "../assets/TeamImages/Vinoth_S.jpg";
import prakash from "../assets/TeamImages/prakash.jpg";
import arunachalam from "../assets/TeamImages/arunachalam.jpg";
import rajasekar from "../assets/TeamImages/Rajasekar.jpg";
import kavibalan from "../assets/TeamImages/Kavibalan.png";
import prem from "../assets/TeamImages/prem_kumar.jpg";
import aswin from "../assets/TeamImages/Aswin.jpg";
import murali from "../assets/TeamImages/Murali.jpg";

import jay from "../assets/TeamImages/Jay.jpg";
import murali_krishnan from "../assets/TeamImages/murali_krishnan.jpg";
import ashokan from "../assets/TeamImages/ashokan.jpg";

export const teamLabel = "Team";

export const teamItems = [
  { name: "Sundar", icon: sundhar, color: "", role: "CEO" },
  { name: "Sat", icon: sat, color: "#FF69B4", role: "" },
  { name: "Partha", icon: partha, color: "", role: "Quality Assurance" },
  { name: "Amritha", icon: amirtha, color: "", role: "Tech Lead" },
  { name: "Priya", icon: priya, color: "red", role: "developer" },
  { name: "Madhu", icon: madhu, color: "", role: "UI/UX" },
  { name: "Kanagalakshmi", icon: kanagalakshmi, color: "", role: "Trainer" },
  { name: "KT Raja", icon: raja, color: "", role: "developer" },
  {
    name: "Sindhudevan",
    icon: sindhudevan,
    color: "blue",
    role: "Tech Lead",
  },
  { name: "Avinash", icon: avinash, color: "", role: "developer" },
  { name: "Gopi", icon: gopi, color: "red", role: "developer" },
  { name: "Rajesh", icon: rajesh, color: "", role: "developer" },
  { name: "Jaison", icon: jaison, color: "", role: "developer" },
  { name: "Siva", icon: siva, color: "lavender", role: "developer" },
  { name: "Vinoth", icon: vinoth, color: "blue", role: "developer" },
  { name: "Arudhra", icon: arudhra, color: "black", role: "developer" },
  { name: "Vinith", icon: vinith, color: "black", role: "developer" },
  { name: "JAYASEELAN", icon: jay, color: "red", role: "animation" },

  { name: "Vinotha", icon: vinotha, color: "", role: "developer" },
  {
    name: "Charumathy",
    icon: charumathy,
    color: "lavender",
    role: "developer",
  },
  { name: "Vignesh", icon: vignesh, color: "red", role: "developer" },
  { name: "Arun", icon: arun, color: "black", role: "developer" },
  { name: "Vignesh", icon: vignesh_v, color: "blue", role: "developer" },
  { name: "Surej", icon: surej, color: "black", role: "developer" },
  { name: "Mukesh", icon: mukesh, color: "red", role: "developer" },

  { name: "Vinoth", icon: vinoth_s, color: "blue", role: "developer" },
  { name: "Revathi", icon: revathi, color: "", role: "developer" },
  { name: "Dinesh Kumar", icon: dinesh, color: "lavender", role: "developer" },
  { name: "Prakash", icon: prakash, color: "black", role: "developer" },
  { name: "Arunachalam", icon: arunachalam, color: "black", role: "developer" },
  { name: "Prem kumar", icon: prem, color: "blue", role: "developer" },
  { name: "Rajasekar", icon: rajasekar, color: "red", role: "developer" },
  { name: "Kavibalan", icon: kavibalan, color: "blue", role: "developer" },
  { name: "Ashwin", icon: aswin, color: "red", role: "Trainer" },
  {
    name: "Murali",
    icon: murali_krishnan,
    color: "red",
    role: "admin",
  },
  { name: "ashokan", icon: ashokan, color: "red", role: "Trainer" },
  { name: "Murali", icon: murali, color: "red", role: "Trainer" },
];
