import React from "react";
import { HeadingText, TechnologyExpertiseContainer } from "../../styles/home";

export default function CustomHeadingWrapper({ label, style }) {
  return (
    <TechnologyExpertiseContainer>
      <HeadingText style={style}>{label}</HeadingText>
    </TechnologyExpertiseContainer>
  );
}
