import { Grid } from "@mui/material";
import React from "react";
import {
  ourClientsItems,
  ourClientsLabel,
} from "../../../constants/ourClients";
import {
  OurClientCarouselContainer,
  OurClientImage,
  OurClientImageContainer,
} from "../../../styles/ourClients";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";

export default function OurClients() {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ padding: "0px 80px" }}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <CustomHeadingWrapper label={ourClientsLabel} />
      </Grid>
      <Grid item xs={12}>
        <OurClientCarouselContainer container>
          {ourClientsItems?.map((item, index) => {
            return (
              <OurClientImageContainer
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <OurClientImage src={item?.icon} style={item?.style} />
              </OurClientImageContainer>
            );
          })}
        </OurClientCarouselContainer>
      </Grid>
    </>
  );
}
