import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  TestimonialCarouselContainer,
  TestimonialMobileStepper,
} from "../../../styles/testimonials";
import { testimonialsItems } from "../../../constants/testimonials";
import TestimonialContentWrapper from "./TestimonialContentWrapper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function TestimonialCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = testimonialsItems?.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClick = (e) => {
    const child = e.target;
    const parent = child.parentNode;
    const childNodes = parent.childNodes;
    const count = childNodes.length;

    for (let i = 0; i < count; ++i) {
      if (child === childNodes[i]) {
        setActiveStep(i);
        break;
      }
    }
  };

  return (
    <TestimonialCarouselContainer>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        {testimonialsItems?.map((step, index) => (
          <div key={step?.id}>
            {Math?.abs(activeStep - index) <= 2 ? (
              <TestimonialContentWrapper testimonialDatas={step} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <TestimonialMobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        onClick={handleClick}
      ></TestimonialMobileStepper>
    </TestimonialCarouselContainer>
  );
}
