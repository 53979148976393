import { Grid } from "@mui/material";
import React from "react";
import { testimonialsLabel } from "../../../constants/testimonials";
import { TestimonialsConatiner } from "../../../styles/testimonials";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";
import TestimonialCarousel from "./TestimonialCarousel";

export default function Testimonials() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} data-aos="zoom-in-down" data-aos-duration="2000">
          <CustomHeadingWrapper label={testimonialsLabel} />
        </Grid>
        <TestimonialsConatiner item xs={12}>
          <TestimonialCarousel />
        </TestimonialsConatiner>
      </Grid>
    </>
  );
}
