import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  technologyExpertiseItems,
  TechnologyExpertiseLabel,
} from "../../../constants/home";
import {
  teachnologyExpertiselabelStyle,
  TechnologyExpertiseIconContainer,
  TechnologyExpertiseText,
  TechnologyExpertiseWrap,
} from "../../../styles/home";
import { centerStyle } from "../../../styles/layOut";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";

export default function TechnologyExpertise() {
  return (
    <TechnologyExpertiseWrap container>
      <Grid item xs={12} data-aos="fade-down" data-aos-duration="2000">
        <CustomHeadingWrapper
          label={TechnologyExpertiseLabel}
          style={teachnologyExpertiselabelStyle}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} m="auto">
        <Grid container>
          {technologyExpertiseItems?.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
                sm={4}
                md={4}
                lg={2.4}
                m="auto"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                alignItems={"center"}
              >
                <Box sx={{ ...centerStyle }}>
                  <TechnologyExpertiseIconContainer>
                    {item?.icon}
                  </TechnologyExpertiseIconContainer>
                  <TechnologyExpertiseText>
                    {item?.label}
                  </TechnologyExpertiseText>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </TechnologyExpertiseWrap>
  );
}
