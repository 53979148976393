import { Grid } from "@mui/material";
import React from "react";
import {
  TestimonialCarouselImage,
  TestimonialContentContainer,
  TestimonialDescriptionText,
  TestimonialImageContainer,
  TestimonialJobDescriptionText,
  TestimonialNameText,
} from "../../../styles/testimonials";

export default function TestimonialContentWrapper({ testimonialDatas }) {
  return (
    <>
      <TestimonialContentContainer container>
        <TestimonialImageContainer item xs={12} sm={12} md={4} lg={2.5}>
          <TestimonialCarouselImage
            src={testimonialDatas?.imgPath}
            alt={testimonialDatas?.name}
          />
        </TestimonialImageContainer>
        <Grid item xs={12} sm={12} md={8} lg={9.5}>
          <TestimonialNameText>{testimonialDatas?.name}</TestimonialNameText>
          <TestimonialJobDescriptionText>
            {testimonialDatas?.jobDescription}
          </TestimonialJobDescriptionText>
          <TestimonialDescriptionText>
            {testimonialDatas?.description}
          </TestimonialDescriptionText>
        </Grid>
      </TestimonialContentContainer>
    </>
  );
}
