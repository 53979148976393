import React from "react";
import CompanyLogo from "./CompanyLogo";
import CompanySpecification from "./CompanySpecification";

export default function HomePage() {
  return (
    <>
      <CompanyLogo />
      <CompanySpecification />
    </>
  );
}
