import React from "react";
import {
  EmployeeName,
  TeamImage,
  TeamImageContainer,
} from "../../../styles/team";
import { Box } from "@mui/material";

export default function TeamImageWrapper({ teamData, onClick }) {
  return (
    <Box
      sx={{
        transition: "all 0.5s",
        ":hover": {
          transform: "scale(1.2 )",
          transition: "all 0.3s",
        },
      }}
    >
      <TeamImageContainer
        onClick={onClick}
        // style={{
        //   borderBottom: `6px solid ${teamData?.color || "greenyellow"}`,
        // }}
      >
        <TeamImage src={teamData?.icon} />
      </TeamImageContainer>
      <EmployeeName>{teamData?.name}</EmployeeName>
    </Box>
  );
}
