import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import backgroundImage from "../assets/backgroundImage.png";

export const ContactUsBgContainer = styled(Box)({
  padding: "10px",
  boxSizing: "border-box",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#3d9970",
});
export const contactHeadingStyles = { color: "#fff", border: "solid 1px #fff" };
export const ContactMapContainer = styled(Grid)({
  height: "330px",
  padding: "20px",
});
export const ContactAddressContainer = styled(Grid)({
  padding: "20px",
});
export const ContactCityName = styled("h3")({
  textTransform: "uppercase",
  letterSpacing: ".2em",
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  fontWeight: "normal",
  color: "#fff",
});
export const ContackAddressText = styled(Box)({
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  color: "#fff",
  lineHeight: "1.5",
  fontSize: "15px",
});
export const mapIframeStyles = {
  height: "100%",
  width: "100%",
  border: "0px",
  borderRadius: "3px",
};
export const contactCenterStyle = {
  display: "flex",
  justifyContent: "center",
};
